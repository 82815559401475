import React from "react"
import { Link } from "gatsby"

export default function Home() {
  return (
    <div>
      <h1 style={{
        textAlign: 'center',
        marginTop: '100px',
        fontSize: '100px',
      }}>Firefly Inventory</h1>
      {/*<ul>*/}
      {/*  <li><Link to='/about'>About</Link></li>*/}
      {/*  <li><Link to='/services'>Services</Link></li>*/}
      {/*  <li><Link to='/contact'>Contact</Link></li>*/}
      {/*</ul>*/}
    </div>
  )
}
